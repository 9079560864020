@font-face {
    font-family: "Trade Gothic W01";
    src: url("/fonts/40775ed4-d9b0-4f75-b874-80f2842332a9.eot?#iefix");
    src: url("/fonts/40775ed4-d9b0-4f75-b874-80f2842332a9.eot?#iefix") format("eot"),
         url("/fonts/24ba8ea7-bbf3-40e0-b924-fd985d79ebca.woff2") format("woff2"),
         url("/fonts/9274bb7f-01c5-45cb-b935-f53683ab6dd6.woff") format("woff"),
         url("/fonts/a94b3f7a-c1fd-4bbe-9273-1f6f081dcb2f.ttf") format("truetype"),
         url("/fonts/b0fb9bf0-3364-4802-9c4e-89202a063331.svg#b0fb9bf0-3364-4802-9c4e-89202a063331") format("svg");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Trade Gothic W01";
    src: url("/fonts/d1380d7c-6e23-49c9-bac2-0f927e77eb17.eot?#iefix");
    src: url("/fonts/d1380d7c-6e23-49c9-bac2-0f927e77eb17.eot?#iefix") format("eot"),
         url("/fonts/2bd4e485-6e2d-478c-94c7-9a7cabf8e98d.woff2") format("woff2"),
         url("/fonts/3e9c6e62-e41c-408b-bef3-ffa454301b17.woff") format("woff"),
         url("/fonts/a74675c3-a15b-41ed-8409-e90f921c3cb8.ttf") format("truetype"),
         url("/fonts/32260af6-2329-4a39-a8a3-3339f87a580b.svg#32260af6-2329-4a39-a8a3-3339f87a580b") format("svg");
    font-weight: 700;
    font-style: normal;
}
